var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    [
      _c(
        "CCardHeader",
        [
          _c("CIcon", { attrs: { name: "cil-notes" } }),
          _c(
            "h5",
            { staticClass: "d-inline ml-2" },
            [
              _vm._v(" " + _vm._s(this.$route.meta.label) + " "),
              [
                _vm._v(" : "),
                _c(
                  "h3",
                  { staticClass: "d-inline" },
                  [
                    _c("CBadge", { attrs: { color: "secondary" } }, [
                      _vm._v(_vm._s(this.itemName)),
                    ]),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "card-header-actions" },
            [
              _c(
                "CButton",
                { attrs: { color: "dark", to: { name: "Devices" } } },
                [
                  _c("CIcon", {
                    staticClass: "align-bottom",
                    attrs: { name: "cil-arrow-thick-left" },
                  }),
                  _vm._v(" Back "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CCardBody",
        [
          _c("alert-section", {
            attrs: {
              successAlertMessage: _vm.successAlertMessage,
              dismissSecs: _vm.dismissSecs,
              dismissSuccessAlert: _vm.dismissSuccessAlert,
              errorAlertMessage: _vm.errorAlertMessage,
              showErrorAlert: _vm.showErrorAlert,
            },
          }),
          _c("CForm", [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c("div", { staticClass: "mb-3" }, [
                _c("h3", { staticClass: "font-weight-bold" }, [
                  _vm._v("General"),
                ]),
                _c(
                  "div",
                  { staticClass: "mb-3 d-flex flex-column flex-md-row" },
                  [
                    _c("CInput", {
                      staticClass: "w-100 mr-2",
                      attrs: {
                        label: "String #ID",
                        placeholder: "String #ID",
                        lazy: false,
                        value: _vm.form.sid,
                        disabled: "",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "form-group w-100" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "d-block",
                            staticStyle: { "font-weight": "500" },
                          },
                          [_vm._v("Software:")]
                        ),
                        _c("CSwitch", {
                          attrs: {
                            checked: _vm.form.software,
                            labelOn: "YES",
                            labelOff: "NO",
                            color: "success",
                            shape: "pill",
                            size: "lg",
                            lazy: false,
                            disabled: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.form.ws_status != null
                  ? _c(
                      "fieldset",
                      { staticClass: "border rounded p-3 bg-light mb-5" },
                      [
                        _c(
                          "legend",
                          {
                            staticClass:
                              "w-auto bg-white rounded py-1 px-2 live-connection-title",
                          },
                          [_vm._v(" Live Connection ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-lg-row flex-column flex-gap-2",
                          },
                          [
                            _vm.form.ws_status != 1
                              ? _c("CInput", {
                                  staticClass:
                                    "w-100 mr-2 ws-status-text offline",
                                  attrs: {
                                    label: "Connection Status",
                                    placeholder: "Connection Status",
                                    lazy: false,
                                    value: "OFFLINE",
                                    disabled: "",
                                  },
                                })
                              : _c("CInput", {
                                  staticClass:
                                    "w-100 mr-2 ws-status-text online",
                                  attrs: {
                                    label: "Connection Status",
                                    placeholder: "Connection Status",
                                    lazy: false,
                                    value: "ONLINE",
                                    disabled: "",
                                  },
                                }),
                            _vm.form.ws_lastcheck_on
                              ? _c("CInput", {
                                  staticClass: "w-100 mr-2",
                                  attrs: {
                                    label: "Last Connection",
                                    placeholder: "Last Connection",
                                    lazy: false,
                                    value: _vm.form.ws_lastcheck_on,
                                    disabled: "",
                                  },
                                })
                              : _vm._e(),
                            _vm.form.ws_lastcheck_off
                              ? _c("CInput", {
                                  staticClass: "w-100",
                                  attrs: {
                                    label: "Last Disconnection",
                                    placeholder: "Last Disconnection",
                                    lazy: false,
                                    value: _vm.form.ws_lastcheck_off,
                                    disabled: "",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "mb-3" }, [
                _c("h3", { staticClass: "mb-3 font-weight-bold" }, [
                  _vm._v("Device Information"),
                ]),
                _c(
                  "div",
                  { staticClass: "mb-3 d-flex flex-column flex-md-row" },
                  [
                    _c("CInput", {
                      staticClass: "w-100 mr-2",
                      attrs: {
                        label: "IMEI",
                        placeholder: "IMEI",
                        lazy: false,
                        value: _vm.form.imei,
                        disabled: "",
                      },
                    }),
                    _c("CInput", {
                      staticClass: "w-100",
                      attrs: {
                        label: "Model",
                        placeholder: "Model",
                        lazy: false,
                        value: _vm.form.model,
                        disabled: "",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mb-3 d-flex flex-column flex-md-row" },
                  [
                    _c("CInput", {
                      staticClass: "w-100 mr-2",
                      attrs: {
                        label: "Serial Number",
                        placeholder: "Serial Number",
                        lazy: false,
                        value: _vm.form.serienr,
                        disabled: "",
                      },
                    }),
                    _c("CInput", {
                      staticClass: "w-100",
                      attrs: {
                        label: "SIM Card Number",
                        placeholder: "SIM Card Number",
                        lazy: false,
                        value: _vm.form.simkaartnr,
                        disabled: "",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mb-3 d-flex flex-column flex-md-row" },
                  [
                    _vm.form.version
                      ? _c("CInput", {
                          staticClass: "w-100 mr-2",
                          attrs: {
                            label: "App Version",
                            placeholder: "App Version",
                            lazy: false,
                            value: _vm.form.version,
                            disabled: "",
                          },
                        })
                      : _vm._e(),
                    _vm.form.os
                      ? _c("CInput", {
                          staticClass: "w-100 mr-2",
                          attrs: {
                            label: "OS",
                            placeholder: "OS",
                            lazy: false,
                            value: _vm.form.os,
                            disabled: "",
                          },
                        })
                      : _vm._e(),
                    _vm.form.os_device
                      ? _c("CInput", {
                          staticClass: "w-100",
                          attrs: {
                            label: "Device OS (Only for MB)",
                            placeholder: "Device OS (Only for MB)",
                            lazy: false,
                            value: _vm.form.os_device,
                            disabled: "",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mb-3 d-flex flex-column flex-md-row" },
                  [
                    _vm.form.connectivity
                      ? _c("CInput", {
                          staticClass: "w-100",
                          attrs: {
                            label: "Connectivity",
                            placeholder: "Connectivity",
                            lazy: false,
                            value: _vm.form.connectivity,
                            disabled: "",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "mb-3 d-flex flex-column flex-md-row" },
                [
                  _c("CTextarea", {
                    staticClass: "w-100",
                    attrs: {
                      rows: "2",
                      label: "Notes",
                      placeholder: "Notes",
                      lazy: false,
                      value: _vm.$v.form.notities.$model,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.$v.form.notities, "$model", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-3 d-flex flex-column flex-md-row" },
                [
                  _c(
                    "div",
                    { staticClass: "form-group w-100 mr-2" },
                    [
                      _c("label", { staticClass: "d-block" }, [
                        _vm._v("Restaurant:"),
                      ]),
                      _c("v-select", {
                        attrs: {
                          reduce: (c) => c.value,
                          placeholder: "Please select..",
                          searchable: true,
                          options: _vm.restaurants,
                        },
                        model: {
                          value: _vm.$v.form.restaurant.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.form.restaurant, "$model", $$v)
                          },
                          expression: "$v.form.restaurant.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.restaurant
                    ? _c(
                        "div",
                        {
                          staticClass: "w-100 form-group",
                          attrs: { role: "group" },
                        },
                        [
                          _c("label", { staticClass: "d-block" }, [
                            _vm._v(" "),
                          ]),
                          _c(
                            "CButton",
                            {
                              attrs: {
                                color: "danger",
                                size: "sm",
                                shape: "pill",
                              },
                              on: { click: () => (_vm.form.restaurant = null) },
                            },
                            [
                              _c("CIcon", {
                                staticClass: "mb-1",
                                attrs: { name: "cil-x" },
                              }),
                              _vm._v(" Unlink "),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "CCardFooter",
        [
          _c(
            "CButton",
            {
              attrs: { color: "primary", disabled: _vm.submitted },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [_vm._v(" Submit ")]
          ),
        ],
        1
      ),
      _c("CElementCover", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.submitted,
            expression: "submitted",
          },
        ],
        attrs: { opacity: 0.4 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }